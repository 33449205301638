import React, { createContext, ReactNode, useContext } from 'react';
import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';

type useNotificationProps = {
  messageApi: MessageInstance
}

const ERROR_DURATION = 5;
const SUCCESS_DURATION = 4;
const TOP_SPACES = 62;

const useNotification = ({ messageApi }: useNotificationProps) => {
  const successNotification = (msgText: string) => {
    messageApi.open({
      type: 'success',
      content: msgText,
      style: { marginTop: TOP_SPACES },
      duration: SUCCESS_DURATION,
    });
  };

  const errorNotification = (msgText="Une erreur est survenue") => {
    messageApi.open({
      type: 'error',
      content: msgText,
      duration: ERROR_DURATION,
    });
  };

  return {
    successNotification,
    errorNotification,
  };
};

type UseNotificationType = ReturnType<typeof useNotification>;

const NotificationContext = createContext<UseNotificationType | undefined>(undefined);

export const useNotificationContext = () => useContext(NotificationContext)!;

export const NotificationProvider = ({ children }: { children: ReactNode }) => {

  const [messageApi, contextHolder] = message.useMessage();

  return (<NotificationContext.Provider value={useNotification({ messageApi })}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
