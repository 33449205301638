import React from 'react';
import Title from 'antd/es/typography/Title';
import styles from './PageTitle.module.scss';

type Props = {
  text: string
}
const PageTitle = ({ text }: Props) => {
  return <Title level={3} className={styles.title}>{text}</Title>;
};

export default PageTitle;