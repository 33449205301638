import getAxiosInstance from './axiosConfig';
import {
  Announce,
  AnnounceStatus,
  DeliveryTypes,
  Don,
  FavAnnonce,
  Troc,
  Trocadona,
  Vente,
} from '../../model/api/Announce';
import { Page } from '../../model/utils/Page';
import { getTokenFromSessionStorage } from '../utils/utils';
import { AnnonceSearch } from '../../pages/SearchAnnonce/SearchAnnonce';
import { GenericResponse } from '../../model/api/GenericResponse';

const ANNOUNCE_API_URL = '/annonces';
const ANNOUNCE_FAV_API_URL = '/favs';
const axiosInstance = getAxiosInstance();

export interface PatchAnnounce {
  title?: string,
  category?: string,
  subcategory?: string,
  offerDescription?: string,
  city?: string,
  postalCode?: string,
  status?: AnnounceStatus,
}

export interface PatchDon extends PatchAnnounce {
}

export interface PatchTroc extends PatchAnnounce {
  counterpartDescription?: string;
}

export interface PatchTrocadona extends PatchAnnounce {
  counterpartDescription?: string,
  becomeDonAt?: string,
}


/**
 * Permet de récupérer une annonce
 * @param annonceId
 */
export function getAnnounce(annonceId: number) {
  return axiosInstance.get<GenericResponse<Announce>>(`${ANNOUNCE_API_URL}/${annonceId}`).then((data) => data.data.content);
}

/**
 * Permet de récupérer un ensemble d'annonce
 */
export function getAnnounceByCriteria(filters: AnnonceSearch) {
  return axiosInstance.get<GenericResponse<Page<Announce>>>(`${ANNOUNCE_API_URL}`, {
    params: {
      isDon: filters.annonce.isDon,
      isTroc: filters.annonce.isTroc,
      isTrocadona: filters.annonce.isTrocadona,
      isVente: filters.annonce.isVente,
      category: filters.annonce.category,
      subcategory: filters.annonce.subcategory,
      search: filters.annonce.search,
      city: filters.annonce.city,
      postalCode: filters.annonce.postalCode,
      page:filters.pagination.current,
      size:filters.pagination.pageSize,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour créer un Troc
 * @param troc
 */
export function postTroc(troc: Troc) {
  return axiosInstance.post<GenericResponse<Troc>>(`${ANNOUNCE_API_URL}/troc`, troc, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour créer un Trocadona
 * @param trocadona
 */
export function postTrocadona(trocadona: Trocadona) {
  return axiosInstance.post<GenericResponse<Trocadona>>(`${ANNOUNCE_API_URL}/trocadona`, trocadona, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour créer un Don
 * @param don
 */
export function postDon(don: Don) {
  return axiosInstance.post<GenericResponse<Don>>(`${ANNOUNCE_API_URL}/don`, don, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

export function postVente(vente: Vente) {
  return axiosInstance.post<GenericResponse<Vente>>(`${ANNOUNCE_API_URL}/vente`, vente, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier un Troc
 * @param trocId
 * @param troc
 */
export function patchTroc(trocId: number, troc: PatchTroc) {
  return axiosInstance.patch<GenericResponse<Troc>>(`${ANNOUNCE_API_URL}/${trocId}/troc`, troc, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier un Trocadona
 * @param trocadonaId
 * @param trocadona
 */
export function patchTrocadona(trocadonaId: number, trocadona: PatchTrocadona) {
  return axiosInstance.patch<GenericResponse<Trocadona>>(`${ANNOUNCE_API_URL}/${trocadonaId}/trocadona`, trocadona, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier une Vente
 * @param venteId
 * @param vente
 */
export function patchVente(venteId: number, vente: Vente) {
  return axiosInstance.patch<GenericResponse<Vente>>(`${ANNOUNCE_API_URL}/${venteId}/vente`, vente, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier un Don
 * @param donId
 * @param don
 */
export function patchDon(donId: number, don: PatchDon) {
  return axiosInstance.patch<GenericResponse<Don>>(`${ANNOUNCE_API_URL}/${donId}/don`, don, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour supprimer une annonce
 * @param announceId
 */
export function deleteAnnounce(announceId: number) {
  return axiosInstance.delete<GenericResponse<String>>(`${ANNOUNCE_API_URL}/${announceId}`).then((value => value.data.content));
}

/**
 * Requête api pour ajouter des images à une annonce
 * @param annonceId
 * @param data
 */
export function addAnnonceImage(annonceId: number, data: FormData) {
  return axiosInstance.post<GenericResponse<String>>(`/images/annonce/${annonceId}`, data,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then(data => data.data.content);
}

/**
 * Requête api pour modifier les images d'une annonce
 * @param annonceId
 * @param data
 */
export function updateAnnonceImage(annonceId: number, data: FormData) {
  return axiosInstance.patch<GenericResponse<String>>(`/images/annonce/${annonceId}`, data,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(data => data.data.content);
}

/**
 * Requête api permettant de renouveler une annonce
 * @param annonceId
 */
export function renewAnnonce(annonceId: number) {
  return axiosInstance.patch<GenericResponse<Announce>>(`${ANNOUNCE_API_URL}/${annonceId}/status/renew`, null,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    })
    .then(data => data.data.content);
}

/**
 * Requête api permettant d'archiver une annonce
 * @param annonceId
 */
export function archiveAnnonce(annonceId: number) {
  return axiosInstance.patch<GenericResponse<Announce>>(`${ANNOUNCE_API_URL}/${annonceId}/status/archive`, null,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    })
    .then(data => data.data.content);
}

/**
 * Requête permettant de récupérer les identifiants des annonces favoris
 */
export function getFavAnnoncesId() {
  return axiosInstance.get<GenericResponse<number[]>>('/users/annonces/favs/ids',
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}

/**
 * Requête permettant de récupérer les annonce favorites
 */
export function getFavAnnonces() {
  return axiosInstance.get<GenericResponse<FavAnnonce[]>>('/users/annonces/favs',
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}


/**
 * Requête permettant d'ajouter une annonce aux favoris
 */
export function addFavAnnonces(annonceId: number) {
  return axiosInstance.post<GenericResponse<String>>(`${ANNOUNCE_FAV_API_URL}/${annonceId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}

/**
 * Requête permettant de supprimer une annonce favorite
 */
export function deleteFavAnnonces(annonceId: number) {
  return axiosInstance.delete<GenericResponse<String>>(`${ANNOUNCE_FAV_API_URL}/annonce/${annonceId}`,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}

/**
 * Permet de récupérer les modes de livraisons disponibles
 */
export function getDeliveryTypes() {
  return axiosInstance.get<GenericResponse<DeliveryTypes[]>>(`${ANNOUNCE_API_URL}/delivery/types`,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => {
      return  data.data.content
  });
}
