import { ReportAnnounceMotifType } from './Report';

export const DON_TYPE = 'DON';
export const TROC_TYPE = 'TROC';
export const TROCADONA_TYPE = 'TROCADONA';
export const VENTE_TYPE = 'VENTE';

export type AnnounceType = typeof DON_TYPE | typeof TROC_TYPE | typeof TROCADONA_TYPE | typeof VENTE_TYPE;

export const ARCHIVEE_STATUT = 'ARCHIVED';
export const EXPIREE_STATUT = 'EXPIRED';
export const VISIBLE_PUBLIQUEMENT_STATUT = 'PUBLIC_VISIBLE';
export const ANNONCE_IMAGES_LIMIT = 3;

export type AnnounceStatus = typeof ARCHIVEE_STATUT | typeof EXPIREE_STATUT | typeof VISIBLE_PUBLIQUEMENT_STATUT;

export interface Announce {
  id: number,
  title: string,
  category: string,
  subcategory: string,
  status: AnnounceStatus,
  offerDescription: string,
  city: string,
  postalCode: string,
  type: AnnounceType,
  images: string[]
  expireAt: Date,
  createdAt: Date,
  lastModifiedDate: Date,
  userId: number
  reports?: ReportDTO[],
  deliveryTypes: string[]
}

export interface Don extends Announce {
}

export interface Troc extends Announce {
  counterpartDescription: string;
}

export interface Trocadona extends Announce {
  counterpartDescription: string,
  becomeDonAt: Date
}

export interface Vente extends Announce {
  price: number
}

export interface FavAnnonce {
  id: number,
  annonce: Announce;
}

export interface ReportDTO {
  id: number,
  userId: number,
  annonceId: number,
  reportType: ReportAnnounceMotifType,
  description: String,
  createdAt: Date,
  lastModifiedDate: Date
}

export enum DeliveryTypes {
  HAND_DELIVERY="Remise en main propre",
  LIVRAISON_ANNONCEUR="Livraison aux frais de l'annonceur",
  LIVRAISON_ACQUEREUR="Livraison aux frais de l'acquéreur",
}

export const getDeliveryTypeValue=(key:string)=>{
  return DeliveryTypes[key as unknown as keyof typeof DeliveryTypes]
}