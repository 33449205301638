import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

let axiosInstance: AxiosInstance | null = null;

function getAxiosInstance() {
  if (axiosInstance) return axiosInstance;
    const defaultOptions: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
    };

  axiosInstance = axios.create(defaultOptions);

  axiosInstance.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
  }/*, async (error) => {
    const originalConfig = error.config;
    if(error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        // Do something, call refreshToken() request for example;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return axiosInstance!(originalConfig);
      }
    }
    return Promise.reject(error);
  }*/);

  return axiosInstance;
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === 'object') handleDates(value);
  }
}


export default getAxiosInstance;
