import  { initializeApp} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_API_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(config);
const auth = getAuth(app);
const analytics = getAnalytics(app);


/*if (window.location.hostname === 'localhost') {
  console.log('Setup emulators config');
  db.useEmulator('localhost', 8080);
  storage.useEmulator('localhost', 9199);
  // @ts-ignore
  auth.useEmulator('http://localhost:9099', { disableWarnings: true });
}*/

export {
  app,
  auth,
  analytics,
};

