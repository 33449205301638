import React, { useState } from 'react';
import { useGlobalDataContext } from '../../context/GlobalDataProvider';
import { Button, Form, Input } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { RegistrationPath, SearchAnnoncePath } from '../../routes/routes';
import styles from './LoginPage.module.scss';
import useUIProps from '../../hooks/useUIProps';
import PageTitle from '../../components/pageTitle/PageTitle';

interface SignInUtilisateurFormType {
    email: string,
    password: string
}

function LoginPage() {
    const { logIn } = useGlobalDataContext();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const {isMinified} = useUIProps();

    const containerDynamicStyle = isMinified? {width:"90%"} :{width: "40%"}
    /**
     * Essaie de logger l'utilisateur
     * @param values contient les valeurs des champs du formulaire
     */
    async function handleSubmit(values: SignInUtilisateurFormType) {
        try {
            setLoading(true);
            const user = await logIn(values.email, values.password);
            if (user && !user.user?.emailVerified) {
                setError('Vous devez activer votre compte pour vous connecter');
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(handleSignInError(e));
            setLoading(false);
        }
    }

    /**
     * Fonction appellée lorsqu'une erreur survient dans le formulaire
     * @param errorInfo
     */
    function onFinishFailed(errorInfo: any) {
        //console.log('Failed:', errorInfo);
    }



    return (
        <div className={styles.container} style={containerDynamicStyle}>
          <PageTitle text="Connectez-vous"/>
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
             >
                {error ? error : null}
                <Form.Item
                  style={{  fontWeight: 'bold'}}
                    label='Email'
                    name='email'
                    rules={[{required: true, message: 'Veuillez saisir votre adresse mail', type: 'email'} , { max:60 } ]}
                >
                    <Input allowClear size={"large"} />
                </Form.Item>

                <Form.Item
                  style={{  fontWeight: 'bold'}}
                    label='Mot de passe'
                    name='password'
                    rules={[{required: true, message: 'Veuillez saisir un mot de passe'}, { max:60 } ]}
                >
                    <Input.Password allowClear size={"large"}  />
                </Form.Item>

                <Form.Item>
                    <Button
                      style={ {width:'100%', marginTop:'2em'}}
                      loading={loading}
                      type={"primary"}
                      htmlType='submit'
                      size={"large"}>
                        Se connecter
                    </Button>
                </Form.Item>
            </Form>
            <div className={styles.registerText}>
                Vous n'avez pas encore de compte ? <Link to={RegistrationPath}>S'inscrire</Link>
            </div>
        </div>
    );

}

/**
 * Permet de retourner un message custom en fonction du code erreur retourné par Firebase
 * @param error l'exception levée par Firebase
 */
const handleSignInError = (error: any): string => {
        if (error.code) {
            switch (error.code) {
                case 'auth/user-disabled':
                    return 'Ce compte a été désactivé.';
                case 'auth/invalid-email':
                    return 'L\'email n\'est pas formaté correctement.';
                case 'auth/user-not-found':
                    return 'L\'utilisateur n\'est pas connu.';
                case 'auth/wrong-password':
                    return 'Mot de passe incorrecte.';
                default:
                    return 'Impossible de se connecter à ce compte';
            }
        } else {
            return 'Impossible de se connecter à ce compte';
        }
    }
;

export default LoginPage;
