import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

const useUIProps = ()=>{

    const screens = useBreakpoint()
    const isMinified = (!screens.lg)
    const xSmall = screens.xs && !screens.sm
    const small = !screens.md && screens.sm
    const medium = !screens.lg && screens.md
    const large = !screens.xl && screens.lg
    const xLarge = !screens.xxl && screens.xl
    const isWayTooLarge = screens.xxl
    return {screens, isMinified, xSmall, small, medium, large, xLarge, isWayTooLarge}
}

export default useUIProps