import getAxiosInstance from './axiosConfig';
import { Particular, Professional, ApiUser, UserContact, UserType } from '../../model/api/ApiUser';
import { Announce, EXPIREE_STATUT, VISIBLE_PUBLIQUEMENT_STATUT } from '../../model/api/Announce';
import { getTokenFromSessionStorage } from '../utils/utils';
import { PageableResponse } from '../../model/api/PageableResponse';
import { GenericResponse } from '../../model/api/GenericResponse';
import { Page } from '../../model/utils/Page';
import { Fav } from '../../model/api/Fav';

const USER_API_URL = '/users';
const axiosInstance = getAxiosInstance();

export interface PostUser {
  email: string,
  name: string,
  firstName: string,
  phoneNumber: string,
  password: string,
  type: UserType,
}

export interface PostParticular extends PostUser {
}

export interface PostProfessional extends PostUser {
  siret: string,
  companyName: string,
  companyAddress: string,
  companyActivity: string,
  companyType: string,
  delegateJob: string,
}

export interface PatchUser {
  phoneNumber?: string,
  name?: string,
  firstName?: string,
}

export interface PatchParticular extends PatchUser {

}

export interface PatchProfessional extends PatchUser {
  companyName?: string,
  companyAddress?: string,
  companyActivity?: string,
  delegateJob?: string,
}

/**
 * Permet de récupérer un utilisateur
 */
export function getUser(): Promise<ApiUser> {
  return axiosInstance.get<GenericResponse<ApiUser>>(USER_API_URL, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Permet de récupérer un utilisateur
 */
export function getUserContact(userId: number) {
  return axiosInstance.get<GenericResponse<UserContact>>(`${USER_API_URL}/${userId}/contact`, {
  }).then((data) => data.data.content);
}

/**
 * Permet de récupérer les annonces d'un utilisateur
 */
export function getActiveUserAnnonces() {
  return axiosInstance.get<GenericResponse<PageableResponse<Announce>>>(`${USER_API_URL}/annonces`, {
    params: {
      status: VISIBLE_PUBLIQUEMENT_STATUT,
    },
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

export function getExpiredUserAnnonces() {
  return axiosInstance.get<GenericResponse<PageableResponse<Announce>>>(`${USER_API_URL}/annonces`, {
    params: {
      status: EXPIREE_STATUT,
    },
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour créer un particulier
 * @param particular
 */
export function postParticular(particular: PostParticular) {
  return axiosInstance.post<GenericResponse<Particular>>(`${USER_API_URL}/particular`, particular).then((data) => data.data.content);
}

/**
 * Requête api pour créer un professionnel
 * @param professional
 */
export function postProfessional(professional: PostProfessional) {
  return axiosInstance.post<GenericResponse<Professional>>(`${USER_API_URL}/professional`, professional).then((data) => data.data.content);
}

/**
 * Requête api pour modifier un particulier
 * @param particular
 */
export function patchParticular(particular: PatchParticular) {
  return axiosInstance.patch<GenericResponse<Particular>>(`${USER_API_URL}/particular`, particular, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier un professionnel
 * @param professional
 */
export function patchProfessional(professional: PatchProfessional) {
  return axiosInstance.patch<GenericResponse<Professional>>(`${USER_API_URL}/professional`, professional, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier le mail d'un utilisateur
 * @param user
 */
export function patchUserMail(user: { email: string }) {
  return axiosInstance.patch<GenericResponse<ApiUser>>(`${USER_API_URL}/email`, user, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Requête api pour modifier le password d'un utilisateur
 * @param user
 */
export function patchUserPassword(user: { password: string }) {
  return axiosInstance.patch<GenericResponse<ApiUser>>(`${USER_API_URL}/password`, user, {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
    },
  }).then((data) => data.data.content);
}

/**
 * Permet de supprimer un utilisateur
 * @param userId
 */
export function deleteUser(userId: number) {
  return axiosInstance.delete<GenericResponse<String>>(`${USER_API_URL}/${userId}`).then((value => value.data.content));
}

/**
 * Permet de vérifier l'email d'un utilisateur
 * @param token
 */
export function verifyEmail(token: string) {
  return axiosInstance.patch<GenericResponse<ApiUser>>(`${USER_API_URL}/verify/${token}`).then((value => value.data.content));
}

/**
 * Permet de vérifier l'email d'un utilisateur
 * @param token
 */
export function verifyUpdateEmail(token: string) {
  return axiosInstance.patch<GenericResponse<ApiUser>>(`${USER_API_URL}/email/update/verify/${token}`).then((value => value.data.content));
}

/**
 * Requête permettant de récupérer les favoris de
 */
export function getFavAnnoncesIds() {
  return axiosInstance.get<GenericResponse<number[]>>(`${USER_API_URL}/annonces/favs/ids`,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}

/**
 * Requête permettant de récupérer les favoris de
 */
export function getFavAnnonces() {
  return axiosInstance.get<GenericResponse<Page<Fav>>>(`${USER_API_URL}/annonces/favs`,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      },
    }).then(data => data.data.content);
}