import { lazy } from 'react';
import { HomeOutlined, SettingOutlined } from '@ant-design/icons';

export const HomePath = '/';
export const SearchAnnoncePath = '/recherche';
export const SearchAnnoncePathLabel = 'Rechercher';
export const LoginPath = '/login';
export const EmailVerificationPath = '/email/verification/:token';
export const UpdateEmailVerificationPath = '/modification/email/verification/:token';
export const RegistrationPath = '/inscription';
export const CreateAnnoncePath = '/creation-annonce';
export const CreateAnnoncePathLabel = 'Ajouter une annonce';
export const AnnonceDetailsPath = '/annonce/details/:id';
export const UpdateAnnoncePath = '/modification-annonce';
export const TutorialPath = '/comment-ça-marche';
export const TutorialPathLabel = 'Comment ça marche ?';
export const UserAnnoncePath = '/annonce/mes-annonces';
export const UserFavouritePath = '/annonce-favoris';
export const UserProfilePath = '/profil';
export const AboutPath = '/à-propos';
export const CGUPath = '/cgu';
export const LegalMentionsPath = '/mentions-légales';
export const ConfidentialPolicyPath = '/confidentialité';
export const AdminPath = '/admin';


const HomePage = lazy(() => import('../pages/Home/HomePage'));
const SearchPage = lazy(() => import('../pages/SearchAnnonce/SearchAnnonce'));
const LoginPage = lazy(() => import('../pages/Authentication/LoginPage'));
const RegistrationPage = lazy(() => import('../pages/Authentication/RegistrationPage'));
const AddAnnoncePage = lazy(() => import('../pages/Annonce/AddAnnonce/AddAnnoncePage'));
const AnnonceDetails = lazy(() => import('../pages/Annonce/AnnonceDetails/AnnonceDetails'));
const UpdateAnnoncePage = lazy(() => import('../pages/Annonce/UpdateAnnonce/UpdateAnnoncePage'));
const UserAnnoncePage = lazy(() => import('../pages/UserAnnonce/UserAnnoncePage'));
const UserFavouritePage = lazy(() => import('../pages/UserFavouriteAnnonce/UserFavouriteAnnonce'));
const UserProfilePage = lazy(() => import('../pages/UserProfile/UserProfile'));
const AboutPage = lazy(() => import('../pages/Static/AboutPage'));
const ConfidentialityPage = lazy(() => import('../pages/Static/ConfidentialityPage'));
const HoToUsePage = lazy(() => import('../pages/Static/HowToUse'));
const GeneralConditionsPage = lazy(() => import('../pages/Static/GeneralConditionsPage/GeneralConditionsPage'));
const LegalMentionsPage = lazy(() => import('../pages/Static/LegalMentions'));
const EmailVerificationPage = lazy(() => import('../pages/Verification/creation/EmailVerificationPage'));
const UpdateEmailVerificationPage = lazy(() => import('../pages/Verification/update/UpdateEmailVerificationPage'));
const AdminPage = lazy(() => import('../pages/Admin/AdminPage'));

export const routes = [
  {
    key: 'app',
    path: HomePath,
    exactPath: true,
    icon: <HomeOutlined />,
    component: <HomePage />,
    isLoginRequired: false,
  },
  {
    key: 'annonce-create',
    path: CreateAnnoncePath,
    exactPath: true,
    component: <AddAnnoncePage />,
    isLoginRequired: true,
  },
  {
    key: 'search',
    path: SearchAnnoncePath,
    exactPath: true,
    icon: undefined,
    component: <SearchPage />,
    isLoginRequired: false,
  },
  {
    key: 'emailVerification',
    path: EmailVerificationPath,
    exactPath: true,
    component: <EmailVerificationPage />,
    isLoginRequired: false,
  },
  {
    key: 'updateEmailVerification',
    path: UpdateEmailVerificationPath,
    exactPath: true,
    component: <UpdateEmailVerificationPage />,
    isLoginRequired: false,
  },
  {
    key: 'login',
    path: LoginPath,
    exactPath: true,
    component: <LoginPage />,
    isLoginRequired: false,
    isAnonymousRequired: true,
  },
  {
    key: 'registration',
    path: RegistrationPath,
    exactPath: true,
    component: <RegistrationPage />,
    isLoginRequired: false,
    isAnonymousRequired: true,
  },
  {
    key: 'annonce-details',
    path: AnnonceDetailsPath,
    exactPath: true,
    component: <AnnonceDetails />,
    isLoginRequired: false,
  },
  {
    key: 'annonce-update',
    path: UpdateAnnoncePath,
    exactPath: true,
    component: <UpdateAnnoncePage />,
    isLoginRequired: true,
  },
  {
    key: 'comment-ça-marche',
    path: TutorialPath,
    exactPath: true,
    icon: <SettingOutlined />,
    component: <HoToUsePage />,
    isLoginRequired: false,
  },
  {
    key: 'annonce/mes-annonces',
    path: UserAnnoncePath,
    exactPath: true,
    component: <UserAnnoncePage />,
    isLoginRequired: true,
  },
  {
    key: 'annonce-favoris',
    path: UserFavouritePath,
    exactPath: true,
    component: <UserFavouritePage />,
    isLoginRequired: true,
  },
  {
    key: 'profil',
    path: UserProfilePath,
    exactPath: true,
    component: <UserProfilePage />,
    isLoginRequired: true,
  },
  {
    key: 'about',
    path: AboutPath,
    exactPath: true,
    component: <AboutPage />,
    isLoginRequired: false,
  },
  {
    key: 'confidentiality',
    path: ConfidentialPolicyPath,
    exactPath: true,
    component: <ConfidentialityPage />,
    isLoginRequired: false,
  },
  {
    key: 'cgu',
    path: CGUPath,
    exactPath: true,
    component: <GeneralConditionsPage />,
    isLoginRequired: false,
  },
  {
    key: 'mentions-légales',
    path: LegalMentionsPath,
    exactPath: true,
    component: <LegalMentionsPage />,
    isLoginRequired: false,
  },
  {
    key: 'admin',
    path: AdminPath,
    exactPath: true,
    component: <AdminPage />,
    isLoginRequired: true,
  },
];
