import React from 'react';
import App from './App';
import { GlobalDataProvider } from './context/GlobalDataProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    },
  },
});
const rootNode = document.getElementById('root');
if (rootNode) {
  createRoot(rootNode)
    .render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <GlobalDataProvider>
            <App />
          </GlobalDataProvider>
        </QueryClientProvider>
      </React.StrictMode>,
    );
}
