import React from 'react';
import {
  CreateAnnoncePath,
  CreateAnnoncePathLabel,
  SearchAnnoncePath,
  SearchAnnoncePathLabel,
  TutorialPath,
  TutorialPathLabel,
} from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { Divider, Drawer, Image, Typography } from 'antd';
import styles from './Drawer.module.scss';
import UserInformation from '../userInformation/UserInformation';

type Props = {
  setSidebarIsVisible: (value: boolean) => void
}
const { Text } = Typography;

const DrawerApp = ({ setSidebarIsVisible }: Props) => {
  const history = useHistory();

  return <Drawer
    width={250}
    className={styles.drawer}
    placement='left'
    closable={false}
    onClose={() => setSidebarIsVisible(false)}
    open={true}
    key='sidebar-drawer'
    maskClosable
  >

    <div className={styles.drawerBody}>
      <div className={styles.drawerRoutes}>
        <div onClick={() => history.push(CreateAnnoncePath)}>
          <Text>{CreateAnnoncePathLabel}</Text>
        </div>
        <Divider />
        <div onClick={() => history.push(SearchAnnoncePath)}>
          < Text>{SearchAnnoncePathLabel}</Text>
        </div>
        <Divider />
        <div onClick={() => history.push(TutorialPath)}>
          < Text> {TutorialPathLabel}</ Text>
        </div>
        <Divider />
        <div>
          <UserInformation />
        </div>
      </div>
      <div className={styles.drawerFooter}>
        <Image
          src={'https://firebasestorage.googleapis.com/v0/b/trocadona-8321d.appspot.com/o/website%2Flogo%2FLOGO_trocadona2.png?alt=media&token=8f504746-6e1c-496b-92b2-6d1ff4c55c07'}
          preview={false}
          width={220} />
      </div>
    </div>
  </Drawer>;
};

export default DrawerApp;