const TOKEN_KEY = 'token';

export function setTokenToSessionStorage(token: string) {
  sessionStorage.setItem(TOKEN_KEY, token);
}

export function getTokenFromSessionStorage() {
  return sessionStorage.getItem(TOKEN_KEY);
}

export function removeTokenFromSessionStorage() {
  sessionStorage.removeItem(TOKEN_KEY);
}


export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || 'unknown'

  return /iPhone|iPod|iPad/.test(platform)
}