import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useGlobalDataContext } from '../context/GlobalDataProvider';
import { HomePath } from './routes';

/**
 * Composant qui permet de router l'utilisateur sur la HomePage s'il est authentifié
 * @param routeProps
 * @constructor
 */
export default function AnonymousRoute({...routeProps}: RouteProps){
  const {isAuthenticated} = useGlobalDataContext();
  if(!isAuthenticated) return <Route {...routeProps}/>;
  else return <Redirect to={HomePath}/>;
}