import React from 'react';
import styles from './FooterLayout.module.scss';
import { Divider, Typography } from 'antd';
import useUIProps from '../../hooks/useUIProps';
import { Link } from 'react-router-dom';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { AboutPath, CGUPath, ConfidentialPolicyPath, LegalMentionsPath } from '../../routes/routes';

const { Title } = Typography;

const FooterLayout = () => {
  const { isMinified } = useUIProps();

  const contactUsButton = <div style={isMinified ? {  marginBottom: '18px' } : { marginBottom: '20px'}}>
    <a
    href='mailto:trocadonadev@gmail.com'> <span style={{fontSize:'16px',fontWeight: '600',lineHeight: '1.5'}}
    >Nous
    Contacter</span></a></div>;

  const socialNetworkButtons = <div className={styles.iconsList}>
    <Link to={{ pathname: 'https://www.facebook.com/Trocadona-102528998136704' }}
          target='_blank'
          style={{ marginRight: '5px' }}>
      <FacebookOutlined style={{ fontSize: '30px', color: 'white' }} />
    </Link>
    <Link to={{ pathname: 'https://www.instagram.com/trocadona.fr/' }}
          target='_blank'>
      <InstagramOutlined style={{ fontSize: '30px', color: 'white' }} />
    </Link>
  </div>;

  const copyrightText = <div style={{
    marginTop: '20px',
    color: 'white',
    fontSize: '.66rem',
    lineHeight: ' .88rem',
  }}>© 2023 Trocadona
  </div>;

  const contextDynamicStyle = !isMinified ? { display: 'flex',
    justifyContent: 'space-around',
    padding: "30px 10px 30px 10px"} : {padding: "10px 5px 10px 5px"};
  const bioDynamicStyle = !isMinified ? { maxWidth: '40%' } : {marginBottom:'1em'};


  return (
    <div className={styles.container} style={contextDynamicStyle}>
      <div className={styles.bioText} style={bioDynamicStyle}>
        <Title level={5}>Favorisons le développement durable, le recyclage, et l'économie circulaire !</Title>
      </div>
      {!isMinified && <div style={{textAlign:'left'}}>
        <Title level={5}>INFORMATIONS LÉGALES</Title>
          <div className={styles.linkLegalInfo}><Link to={AboutPath}>À propos</Link></div>
          <div className={styles.linkLegalInfo}><Link to={LegalMentionsPath}>Mentions légales</Link></div>
          <div className={styles.linkLegalInfo}><Link to={CGUPath}>Conditions générales d'utilisation</Link></div>
          <div className={styles.linkLegalInfo}><Link to={ConfidentialPolicyPath}>Politique de confidentialité</Link>
        </div>
      </div>
      }
      <div>
        {contactUsButton}
        {socialNetworkButtons}
        {copyrightText}
      </div>

    </div>
  );
};

export default FooterLayout;
