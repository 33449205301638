import React, { useEffect, useState } from 'react';

import { Button, Image } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react';

import styles from './Shell.module.scss';
import {
  CreateAnnoncePath,
  CreateAnnoncePathLabel,
  HomePath,
  SearchAnnoncePath,
  SearchAnnoncePathLabel,
  TutorialPath,
  TutorialPathLabel,
} from '../../routes/routes';
import useUIProps from '../../hooks/useUIProps';
import { PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import UserInformation from './menu/userInformation/UserInformation';
import DrawerApp from './menu/drawer/Drawer';

const Shell = () => {

  const [sidebarIsVisible, setSidebarIsVisible] = useState<boolean>(false);
  const location = useLocation();
  const { screens } = useUIProps();
  const isMinified = (!screens.lg);

  const isAddAnnonceVisible = location.pathname === CreateAnnoncePath;
  const isSearchAnnonceVisible = location.pathname === SearchAnnoncePath;
  const isHowItWorksVisible = location.pathname === TutorialPath;

  useEffect(() => {
    if (sidebarIsVisible) setSidebarIsVisible(false);
  }, [location]);

  const renderHamburger = <Hamburger
    toggled={sidebarIsVisible}
    toggle={setSidebarIsVisible}
    size={30}
    color={'white'}
    distance={'sm'}
    rounded

  />;

  const renderLogo =
  <Link to={HomePath}>
      <Image
        src={'https://firebasestorage.googleapis.com/v0/b/trocadona-8321d.appspot.com/o/website%2Flogo%2FLOGO_trocadona.png?alt=media&token=f7be544d-5c9a-4074-94a6-f2291d60ae59'}
        preview={false}
        height={70}
        width={134}
        className={styles.logoImage} />
    </Link>;

  const actionButtons = <div>
    <Link to={CreateAnnoncePath}>
      <Button type='primary'
              icon={<PlusSquareOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />}
              hidden={isAddAnnonceVisible}

      >
        {CreateAnnoncePathLabel}
      </Button>
    </Link>
    <Link to={SearchAnnoncePath}>
      <Button type='primary'
              icon={<SearchOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />}
              hidden={isSearchAnnonceVisible}
              style={{ marginLeft: '1em' }}
      >
        {SearchAnnoncePathLabel}
      </Button>
    </Link>
    <Link to={TutorialPath}>
      <Button type='primary'
              hidden={isHowItWorksVisible}
              style={{ marginLeft: '1em' }}

      >
        {TutorialPathLabel}
      </Button>
    </Link>
  </div>;


  return (
    <div className={styles.headerBar}>
      <div>
        {
          isMinified ? renderHamburger : renderLogo
        }
      </div>
      <div>
        {isMinified ? renderLogo : actionButtons}
      </div>
      <div>
        {!isMinified && <UserInformation />}
      </div>
      {sidebarIsVisible && <DrawerApp setSidebarIsVisible={setSidebarIsVisible} />}
    </div>
  );
};

export default Shell;
