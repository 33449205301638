import React, { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/fr';
import frFR from 'antd/locale/fr_FR';

import variables from '../../styles/variable.module.scss';

type AntdThemeProviderProps = {
  children: ReactNode
}
const AntdThemeProvider = ({ children }: AntdThemeProviderProps) => {
  return <ConfigProvider
    locale={frFR}
    theme={{
      token: {
        colorPrimary: variables.primaryColor,
        fontFamily: 'Montserrat',
        fontSize: 16,
      },
      hashed: false
    }}
  >
    {children}
  </ConfigProvider>;
};

export default AntdThemeProvider;