import { useQuery } from 'react-query';
import { getDeliveryTypes } from '../../services/api/announce';

export function useDeliveryTypes() {

  const { data: deliveryTypes = [] } = useQuery('deliveryTypes',
    async () => await getDeliveryTypes().then((res) => res), {
      refetchOnMount: false,
    });


  return {
    deliveryTypes,
  };
}
