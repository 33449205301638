import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth';
import { auth } from '../firebase';

import { getUser } from '../services/api/user';
import { setTokenToSessionStorage } from '../services/utils/utils';
import { useCategory } from './hooks/category';
import { useFavAnnonce } from './hooks/favAnnonce';
import { useDeliveryTypes } from './hooks/deliveryTypes';
import { ApiUser } from '../model/api/ApiUser';
import { UserCredential } from '@firebase/auth';

type UserData = {
  api: ApiUser,
  firebase: User
}
const useGlobalData = () => {
  const [currentUser, setCurrentUser] = useState<UserData>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { categories, getCategoryFromSubcategoryName, getSubcategoryFromLabel } = useCategory();
  const { favAnnoncesId, addFavAnnonce, removeFavAnnonce } = useFavAnnonce(isAuthenticated);
  const { deliveryTypes } = useDeliveryTypes();

  const [positionToScrollWhenSearchAnnonce, setPositionToScrollWhenSearchAnnonce] = useState<{ x: number, y: number } | undefined>(undefined);
  //Un utilisateur avec ce mail existe déjà
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      const userAuthenticated = firebaseUser !== null && firebaseUser.emailVerified;
      const setCurrentUserAsync = async () => {
        if (firebaseUser) {
          const token = await firebaseUser.getIdToken();
          setTokenToSessionStorage(token);
          try {
            const user = await getUser();
            user.token = token;
            if (user) {
              setCurrentUser({ api: user, firebase: firebaseUser as User });
              setIsAuthenticated(true);
            } else {
              setIsAuthenticated(false);
              setCurrentUser(undefined);
            }
          } catch (error) {
            console.error('Impossible de récupérer le user de la base');
          }
        }
      };
      if (userAuthenticated) {
        setCurrentUserAsync().catch((e) => console.log('Impossible de set l\'utilisateur courant', e));
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logIn = (email: string, password: string) : Promise<UserCredential> => signInWithEmailAndPassword(auth, email, password);
  const logOut = (): Promise<void> => signOut(auth);
  const reauthenticateFirebaseUserWithCredential = (password: string) : Promise<UserCredential> => {
    if (currentUser?.firebase) {
      const credentials = EmailAuthProvider.credential(currentUser?.firebase.email!, password);
      return reauthenticateWithCredential(currentUser?.firebase! , credentials);
    } else {
      return Promise.reject('Aucun user dans la session courante');
    }
  };

  return {
    currentUser,
    isAuthenticated,
    logIn,
    logOut,
    reauthenticateFirebaseUserWithCredential,
    categories,
    deliveryTypes,
    getCategoryFromSubcategoryName,
    getSubcategoryFromLabel,
    favAnnoncesId,
    addFavAnnonce,
    removeFavAnnonce,
    positionToScrollWhenSearchAnnonce,
    setPositionToScrollWhenSearchAnnonce,
  };
};

type UseGlobalDataType = ReturnType<typeof useGlobalData>;

const GlobalDataContext = createContext<UseGlobalDataType | undefined>(undefined);

export const useGlobalDataContext = () => useContext(GlobalDataContext)!;

export const GlobalDataProvider = ({ children }: { children: ReactNode }) => (
  <GlobalDataContext.Provider value={useGlobalData()}>
    {children}
  </GlobalDataContext.Provider>
);
