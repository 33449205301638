import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useGlobalDataContext } from '../context/GlobalDataProvider';
import LoginPage from '../pages/Authentication/LoginPage';

/**
 * Composant qui permet de router l'utilisateur sur la LoginPage s'il n'est authentifié
 * @param routeProps
 * @constructor
 */
export default function PrivateRoute({ ...routeProps }: RouteProps) {
  const { isAuthenticated } = useGlobalDataContext();
  if (isAuthenticated) return <Route {...routeProps} />;
  else return <LoginPage />;
}
