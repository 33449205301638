import { addFavAnnonces, deleteFavAnnonces, getFavAnnoncesId } from '../../services/api/announce';
import { useEffect, useState } from 'react';

export const useFavAnnonce = (isAuthenticated: boolean) => {

  const [favAnnoncesId, setFavAnnoncesId] = useState<number[]>([]);

  useEffect(() => {
    if(isAuthenticated){
      getFavAnnoncesId().then((res) => setFavAnnoncesId(res));
    }
  }, [isAuthenticated]);

  const addFavAnnonce = (annonceId: number) => {
    setFavAnnoncesId(prevState => {
      return [...prevState, annonceId];
    });
    return addFavAnnonces(annonceId)
  };

  const removeFavAnnonce = (annonceId: number) => {
    setFavAnnoncesId(prevState => {
      return prevState.filter((annonce) => annonce !== annonceId);
    });
    return deleteFavAnnonces(annonceId);
  };

  return {
    favAnnoncesId,
    addFavAnnonce,
    removeFavAnnonce
  };
};
