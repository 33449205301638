import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HomePath, routes } from './routes';
import PrivateRoute from './PrivateRoute';
import AnonymousRoute from './AnonymousRoute';

const Routes = () => {
  return (
    <Switch>
      {routes.map(
        (route) =>
          (route.isAnonymousRequired) ?
            <AnonymousRoute exact={route.exactPath} path={route.path}
                            key={route.key}>{route.component}</AnonymousRoute> :
            route.isLoginRequired ?
              <PrivateRoute exact={route.exactPath} path={route.path} key={route.key}>
                {route.component}
              </PrivateRoute> :
              <Route exact={route.exactPath} path={route.path} key={route.key}>
                {route.component}
              </Route>,
      )}
      <Redirect from='*' to={HomePath} />
    </Switch>
  );
};

export default Routes;