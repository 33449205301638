import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert, Button, Col, Layout, Spin } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Shell from './components/shell/Shell';

import 'antd/dist/reset.css';
import './styles/antd.overrides.scss';

import styles from './App.module.scss';
import FooterLayout from './components/footer/FooterLayout';
import Routes from './routes/Route';
import AntdThemeProvider from './components/antdThemeProvider/AntdThemeProvider';
import { NotificationProvider } from './context/NotificationProvider';
import Spinner from './components/spinner/Spinner';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <Col className={styles.container} span={14} offset={4}>
      <div role='alert'>
        <Alert type='error' message='Un problème est survenu :' />
        <pre>{error.message}</pre>
        <Button onClick={resetErrorBoundary} size={'large'}>Réessayer</Button>
      </div>
    </Col>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          window.location.reload();
        }}
      >
        < AntdThemeProvider>
          <NotificationProvider>
            <Layout className={styles.App}>
              <Header className={styles.Header}>
                <Shell />
              </Header>
              <Content className={styles.Content}>
                <Suspense fallback={<Spinner/>}>
                  <Routes />
                </Suspense>
              </Content>
              <Footer className={styles.Footer}>
                <FooterLayout />
              </Footer>
            </Layout>
          </NotificationProvider>
        </AntdThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}


export default App;