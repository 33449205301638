import React, { useMemo } from 'react';

import styles from './UserInformation.module.scss';
import { Button, Dropdown } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useGlobalDataContext } from '../../../../context/GlobalDataProvider';
import { HomePath, LoginPath, UserAnnoncePath, UserFavouritePath, UserProfilePath } from '../../../../routes/routes';
import { Link, useHistory } from 'react-router-dom';
import { removeTokenFromSessionStorage } from '../../../../services/utils/utils';
import { useQueryClient } from 'react-query';
import useUIProps from '../../../../hooks/useUIProps';

const UserInformation = () => {
  const history = useHistory();
  const { isAuthenticated, logOut } = useGlobalDataContext();
  const queryClient = useQueryClient();
  const { isMinified } = useUIProps();

  const renderConnexionButton = useMemo(() =>
    <Link to={LoginPath}>
      <Button type='primary'
              icon={<UserOutlined />}
              className={styles.connexion}>
        Connexion
      </Button>
    </Link>, []);

  const items: any = useMemo(() => [
    {
      key: '1',
      label: <Link to={UserProfilePath}>Mon profil</Link>,
    },
    {
      key: '2',
      label: <Link to={UserAnnoncePath}>Mes annonces</Link>,
    },
    {
      key: '3',
      label: <Link to={UserFavouritePath}>Mes favoris</Link>,
    },

    {
      key: '4',
      label: <Button
        onClick={() => {
          logOut()
            .then(() => {
              removeTokenFromSessionStorage();
              queryClient.invalidateQueries('user').then(() => history.push(HomePath));
            })
            .catch(() => console.error('Une erreur est survenue lors de la déconnexion'));
        }}>Déconnexion</Button>,
    },
  ], [logOut, removeTokenFromSessionStorage, queryClient]);

  const renderUserMenu = useMemo(() => {
      return <Dropdown
        trigger={['click']}
        menu={{ items }}
        placement={'bottom'}
        overlayStyle={{ marginTop: '200px' }}
        arrow
      >
        <a className={isMinified ? styles.blackText : styles.whiteText} onClick={e => e.preventDefault()}>
          <UserOutlined /> Mon compte <DownOutlined />
        </a>
      </Dropdown>;
    }
    , [items, isMinified]);


  return (
    <div className={styles.container}>
      {isAuthenticated ? renderUserMenu : renderConnexionButton}
    </div>
  );
};

export default UserInformation;
