import { useQuery } from 'react-query';
import { getCategories } from '../../services/api/staticData';

export function useCategory() {

  const { data: categories = [] } = useQuery('categories',
    async () => await getCategories().then((res) => res.data.content), {
      refetchOnMount: false,
    });

  const getCategoryFromSubcategoryName = (subcategoryName: string) =>
    categories?.find((category) =>
      category.subcategories.find((subcategory) =>
        subcategory.name === subcategoryName))?.name;

  const getSubcategoryFromLabel = (subcategoryLabel: string) =>
    categories.flatMap(c => c.subcategories).find((subcategory) =>
      subcategory.label === subcategoryLabel)?.name;


  return {
    categories,
    getCategoryFromSubcategoryName,
    getSubcategoryFromLabel,
  };
}
